
import Translator from "../../translator/Translator";

export default function Row(props) {
    return (
        <div className="row mb-3">
            <div className="col-md-4 col-lg-3">
                <a href={props.link}
                   target="_blank"
                   rel="noreferrer nofollow"
                   className="card portfolio-item portfolio-title text-center nav-link">
                    <div className="card-header bg-gradient bg-dark">
                        <span className="portfolio-title">{props.title}</span>
                    </div>
                    <div className="card-body">
                        <img src={props.image}
                             width="200"
                             height="200"
                             className="img-thumbnail portfolio-item"
                             alt={props.title + ' logo'}/>
                    </div>
                </a>
            </div>
            <div className="col-md-8 col-lg-9">
                <div className="card border-0 bg-gradient bg-dark">
                    <div className="card-header">
                        <span className="portfolio-title">{props.from} &mdash; {props.to}</span>
                    </div>
                    <div className="card border-0 bg-dark">
                        <div className="card-header">
                            <span className="portfolio-title">{Translator.trans("Stack")}</span>
                        </div>
                        <div className="card-body text-gray">
                            {props.stack}
                        </div>
                    </div>
                    <div className="card border-0 bg-dark">
                        <div className="card-header">
                            <span className="portfolio-title">{Translator.trans("Responsibilities")}</span>
                        </div>
                        {props.responsibilities.map((responsibility, index) => (
                            <div className="card-body text-gray" key={props.title + "_responsibility_" + index}>
                                {responsibility}
                            </div>
                        ))}
                    </div>
                </div>
                <div className="card-body p-1 text-gray bg-gradient">
                </div>
            </div>
        </div>
    )
}